import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
// import Button from "@mui/material/Button";

const Profile = (props) => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (props.user["given_name"]) {
      setFirstName(props.user["given_name"]);
    }
    if (props.user["name"]) {
      setLastName(props.user["name"]);
    }
    if (props.user["extension_company"]) {
      setCompany(props.user["extension_company"]);
    }
    if (props.user["jobTitle"]) {
      setJobTitle(props.user["jobTitle"]);
    }
    if (props.user["emails"][0]) {
      setEmail(props.user["emails"][0]);
    }
  }, [props.user]);

  const handleChangeFirstName = (e) => {
    e.preventDefault();
    setFirstName(e.target.value);
  };

  const handleChangeCompany = (e) => {
    e.preventDefault();
    setCompany(e.target.value);
  };

  const handleChangeLastName = (e) => {
    e.preventDefault();
    setLastName(e.target.value);
  };

  const handleChangeJobTitle = (e) => {
    e.preventDefault();
    setJobTitle(e.target.value);
  };

  const handleChangeEmail = (e) => {
    //NOTE: needs additional handling
    e.preventDefault();
    setEmail(e.target.value);
  };

  return (
    <Box>
      <h2>Profile</h2>

      <Stack spacing={3}>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="first-name">First Name</InputLabel>
          <Input
            id="first-name"
            value={firstname}
            onChange={handleChangeFirstName}
            disabled
          />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="last-name">Last Name</InputLabel>
          <Input
            id="component-simple"
            value={lastname}
            onChange={handleChangeLastName}
            disabled
          />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            id="component-simple"
            value={email}
            onChange={handleChangeEmail}
            disabled
          />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="job-title">Job Title</InputLabel>
          <Input
            id="component-simple"
            value={jobTitle}
            onChange={handleChangeJobTitle}
            disabled
          />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="company">Company</InputLabel>
          <Input
            id="component-simple"
            value={company}
            onChange={handleChangeCompany}
            disabled
          />
        </FormControl>
        {/* Billing address o.ä. */}
        {/* <Button
          variant="contained"
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            marginRight: "12px",
            color: "white",
          }}
        >
          Save
        </Button> */}
      </Stack>
    </Box>
  );
};

export default Profile;
