import React from "react";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme, color }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: color,
  overflow: "hidden",
  whiteSpace: "pre-line",

}));

export default function Outlog({ status, content }) {
  let msg;
  switch (status) {
    case 1: //Processing
      msg = "rgba(0, 0, 0, 0.6)";
      break;
    case 2: //Success
      msg = "rgba(0, 0, 0, 0.6)";
      break;
    case 3: //Error
      msg = "#c62828";
      break;
    default: //Something went wrong
      msg = "#c62828";
      // console.log("ERROR");
      break;
  }

  return <Item color={msg}>{content}</Item>;
}
