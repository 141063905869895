import React, { useEffect, useState, createContext, useReducer } from "react";
import "./App.css";
import authentication from "react-azure-b2c";
import Layout from "./components/Layout";
import { v4 as uuidv4 } from "uuid";

// #expiresOn
// fromCache

// Create context object
export const UserContext = createContext();

export const AppContext = createContext();
const initialState = {};

function reducer(state, action) {
  switch (action.type) {
    case "SET":
      return action.data;
    case "REMOVE":
      let { [action.data]: _, ...result } = state;
      return result;
    case "ADD":
      var index = Object.keys(state).indexOf(action.data) + 1;
      var newObj = addToObject(state, [uuidv4()], action.property, index);
      return newObj;
    case "UPDATE":
      var copyObj = state;
      copyObj[action.key] = action.data;
      return copyObj;
    default:
      return initialState;
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState("");

  useEffect(() => {
    const userClaims = authentication.getAccessToken().idTokenClaims;
    setUser(userClaims);
  }, []);

  var token = authentication.getAccessToken().accessToken;

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <div className="App">
        <Layout user={user} token={token}></Layout>
      </div>
    </AppContext.Provider>
  );
}

export default App;

const addToObject = function (obj, setKey, setItem, position) {
  var map = new Map();

  Object.entries(obj).forEach(function ([key, item], index) {
    if (index === position) {
      map.set(setKey, setItem);
    }
    map.set(key, item);
  });

  //Put at the end
  if (position >= Object.keys(obj).length || !position) {
    map.set(setKey, setItem);
  }

  var newObj = Object.fromEntries(map);
  return newObj;
};
