import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Input from "@mui/material/Input";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../App";

const style = {
  position: "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid darkgray",
  boxShadow: 24,
  p: 4,
};

export default function FileExplorer({
  updateFile,
  saveFile,
  saved,
  handleCloseParent,
  file,
}) {
  //File management
  const [files, setFiles] = useState([]);
  const [tmpFile, setTmpFile] = useState("file.ve");
  const [errorResponse, setErrorResponse] = useState(<></>);
  const [errorFileSystem, setErrorFileSystem] = useState(<></>);

  /* eslint-disable no-unused-vars */
  const { state, dispatch } = useContext(AppContext);
  /* eslint-disable no-unused-vars */

  //Handle saving
  const [openSave, setOpenSave] = useState(false);
  const handleOpenSave = () => setOpenSave(true);
  const handleCloseSave = () => setOpenSave(false);
  const [saving, setSaving] = useState("");

  //Handle file view
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setErrorResponse(<></>);
    setOpen(true);
  };
  const [openError, setOpenError] = useState(false);
  const handleCloseError = () => setOpenError(false);

  //Initialize with loading
  useEffect(() => {
    setErrorFileSystem(<></>);
    axios
      .get("/storage?get_container=True", {
        "axios-retry": {
          retries: 3,
        },
      })
      .then((response) => {
        setFiles(response.data.files);
        setErrorFileSystem(<></>);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response);
        setErrorFileSystem(
          <p>
            <i style={{ color: "red" }}>Could not connect to file system.</i>
          </p>
        );
        setLoading(false);
      });
  }, []);

  //Add file /storageSave
  function handleAddFile(value) {
    axios
      .post("/storageSave?get_container=True", {
        params: {
          file_name: value["tmpFile"],
          overwrite: "no",
        },
        file_content: JSON.stringify({
          [uuidv4()]: "code//Start typing",
        }),
      })
      .then((res) => {
        handleClose();
        setFiles([...files, value["tmpFile"]]);
      })
      .catch((err) => {
        //Return error
        if (err.response.data) {
          setErrorResponse(
            <ListItem sx={{ color: "red" }}>
              {err.response.data["msg"]}
            </ListItem>
          );
        } else {
          setOpenError(true);
        }
      });
  }

  //Deletes file /storageDelete
  function handleDeleteFile(value) {
    axios
      .post("/storageDelete?get_container=True", {
        params: {
          file_name: value["data"],
        },
      })
      .then((res) => {
        setFiles(files.filter((item) => item !== value["data"]));
      })
      .catch((err) => {
        //Try to update files.
        axios
          .get("/storage?get_container=True", {
            "axios-retry": {
              retries: 3,
            },
          })
          .then((res) => setFiles(res.data.files), setLoading(false))
          .catch((err) =>
            setFiles([
              <i style={{ color: "red" }}>
                Could not connect to file system. Please try again.
              </i>,
            ])
          );
      });

    if (value["data"] === file) {
      updateFile("");
      dispatch({ type: "SET", data: {} });
    }
  }

  //Open file
  function handleEditFile(value) {
    if (saved) {
      //If alrady saved - don't show dialog.
      openFile(value);
    } else {
      //If not saved - show dialog.
      handleOpenSave();
      setSaving(value);
    }
  }

  //Saving changes dialog
  function getRes(value) {
    handleCloseSave();
    handleCloseParent();

    switch (value) {
      case "Yes":
        saveFile().then((res) => {
          if (res.status === 200) {
            openFile(saving);
          }
        });
        break;
      case "No":
        openFile(saving);
        break;
      default:
        break;
    }
  }

  //Function handling openFile /storageEdit
  const openFile = async (value) => {
    let response = axios.post("/storageEdit?get_container=True", {
      "axios-retry": {
        retries: 3,
      },
      params: {
        file_name: value["data"],
      },
    });
    return response
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "SET", data: JSON.parse(res.data["msg"]) });
          updateFile(value["data"]);
          handleCloseParent();
        } else {
          setOpenError(true);
        }
        return res;
      })
      .catch((err) => {
        setOpenError(true);
        return Promise.reject(err);
      });
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        onClick={handleOpen}
        sx={{
          backgroundColor: "white",
          color: "black",
          ":hover": {
            color: "rgba(0, 0, 0, 0.6)",
            backgroundColor: "white",
          },
        }}
      >
        Add File
      </Button>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            padding: "3em 0",
            backgroundColor: "inherit",
          }}
        >
          <CircularProgress
            sx={{ color: "rgba(0, 0, 0, 0.6)", opacity: "0.7" }}
          />
        </div>
      ) : (
        <>
          {open ? (
            <Modal open={open} onClose={handleClose}>
              <Box sx={style}>
                <Grid container>
                  <Grid item p={(10, 5)} xs={12}>
                    <List>
                      <ListItem button>
                        <ListItemIcon>
                          <InsertDriveFileOutlinedIcon />
                        </ListItemIcon>
                        <Input
                          placeholder="file.ve"
                          defaultValue="file.ve"
                          onChange={(e) => {
                            setTmpFile(e.target.value);
                          }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="save"
                            onClick={() => handleAddFile({ tmpFile })}
                          >
                            <SaveSharpIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      {errorResponse}
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          ) : (
            <>
              {errorFileSystem}
              <List>
                {files.map((data, index) => {
                  return (
                    <ListItem
                      button
                      key={index}
                      onClick={() => handleEditFile({ data })}
                    >
                      <ListItemIcon>
                        <InsertDriveFileOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={data} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteFile({ data })}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <Modal open={openSave} onClose={handleCloseSave}>
                <Box sx={style}>
                  <Grid container>
                    <Grid item p={(10, 5)} xs={12}>
                      Do you want to save any changes you made?
                    </Grid>

                    <Grid item xs={6}>
                      <Button onClick={() => getRes("Yes")}>Yes</Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button onClick={() => getRes("No")}>No</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              <Modal open={openError} onClose={handleCloseError}>
                <Box sx={style}>
                  <Grid container>
                    <Grid item p={(10, 5)} xs={12}>
                      Error
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
}
