import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonIcon from "@mui/icons-material/Person";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FolderIcon from "@mui/icons-material/Folder";
import FeedbackRoundedIcon from "@mui/icons-material/FeedbackRounded";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FileExplorer from "./FileExplorer";
import Communication from "./Communication";
import Help from "./Help";
import Profile from "./Profile";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "80%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid darkgray",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export const Sidebar = ({ user, updateFile, saveFile, saved, file }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalView, setModalView] = useState();
  const timeout = 900;

  function handleCloseParent() {
    handleClose();
  }

  const handleOpenFiles = () => {
    setModalView(
      <FileExplorer
        updateFile={updateFile}
        saveFile={saveFile}
        saved={saved}
        handleCloseParent={handleCloseParent}
        file={file}
      />
    );
    handleOpen();
  };

  const handleOpenHelp = () => {
    setModalView(<Help />);
    handleOpen();
  };
  const handleOpenProfile = () => {
    setModalView(<Profile user={user} />);
    handleOpen();
  };

  const handleOpenCommunication = () => {
    setModalView(<Communication user={user} />);
    handleOpen();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>{modalView}</Box>
      </Modal>
      <div
        style={{
          height: "100%",
          borderRight: "thin lightgray solid",
        }}
      >
        <List>
          <ListItem button key="Profile" onClick={handleOpenProfile}>
            <ListItemIcon>
              <Tooltip
                title="Profile"
                arrow
                TransitionProps={{ timeout: { timeout } }}
                placement="right"
              >
                <PersonIcon />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
          <Divider />
          <ListItem button key="Files" onClick={handleOpenFiles}>
            <ListItemIcon>
              <Tooltip
                title="Files"
                arrow
                TransitionProps={{ timeout: { timeout } }}
                placement="right"
              >
                <FolderIcon />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
          <Divider />
          <ListItem button key="Help" onClick={handleOpenHelp}>
            <ListItemIcon>
              <Tooltip
                title="Help"
                arrow
                TransitionProps={{ timeout: { timeout } }}
                placement="right"
              >
                <HelpOutlineIcon />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
          <Divider />
          <ListItem
            button
            key="Communication"
            onClick={handleOpenCommunication}
          >
            <ListItemIcon>
              <Tooltip
                title="Report"
                arrow
                TransitionProps={{ timeout: { timeout } }}
                placement="right"
              >
                <FeedbackRoundedIcon />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
