import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import authentication from "react-azure-b2c";
import logo from "../assets/variate-log-hq.png"; //TODO -> Change to .svg

const Appbar = (props) => {
  function signOut() {
    authentication.signOut();
    authentication.cleanUpStorage();
  }

  return (
    <AppBar position="fixed" style={{ background: "#f6f6f6", zIndex: 1251 }}>
      <Toolbar>
        <img src={logo} alt={"logo"} style={{ height: "35px" }} />
        <Typography
          style={{
            color: "darkgray",
            marginLeft: "25px",
            fontWeight: "bold",
          }}
        >
          BETA
        </Typography>
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "#242526",
              paddingRight: "25px",
            }}
          >
            Hello {props.user["given_name"]}
          </Typography>
          <Button
            style={{
              color: "#242526",
            }}
            onClick={signOut}
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Appbar;
