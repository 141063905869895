import React, { useRef, useEffect, useState, useContext } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Editor from "@monaco-editor/react";
import Outlog from "./Outlog";
import { AppContext } from "../App";
import MarkdownCell from "./MarkdownCell";

// addCommand with Keyboard-Shortcuts does not differentiate between cells

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingBottom: 24,
  textAlign: "left",
  color: theme.palette.text.secondary,
  overflow: "hidden",
}));

export default function Cell({
  path,
  cellType,
  cellContent,
  getOutlog,
  executeCode,
  executeNL,
  removeFunction,
  focusFunction,
  saved,
  handleNotSaved,
  focused
}) {
  /* eslint-disable no-unused-vars */
  const { state, dispatch } = useContext(AppContext);
  /* eslint-disable no-unused-vars */

  const editorRef = useRef(null);
  const monacoRef = useRef(null);

  const [height, setHeight] = useState(23);
  const [isMounted, setIsMounted] = useState(false);
  const [code, setCode] = useState(cellContent);
  const [content, setContent] = useState();
  const [showOutput, setShowOutput] = useState(false);
  const [showText, setShowText] = useState(false);

  const [msgStatus, setMsgStatus] = useState();
  const [isLoading, setLoading] = useState(false);
  const [cellValue, setCellValue] = useState(cellContent);

  const enableText = () => {
    setShowText(false);
    setCellValue(code);
  };

  const onDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.click();
  };

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    monacoRef.current = monaco;

    setIsMounted(true);

    editorRef.current.updateOptions({
      scrollBeyondLastLine: false,
      minimap: {
        enabled: false,
      },
      scrollbar: {
        handleMouseWheel: false,
      },
      lineNumbers: "off",
      automaticLayout: true,
    });
  }

  function handleEditorDidChange(value, e) {
    setCode(value);
    setHeight(editorRef.current.getModel().getLineCount() * 23);
    editorRef.current.layout();

    if (saved) {
      handleNotSaved();
    }
  }

  function handleRun() {
    //Check if input
    if (code.trim().length !== 0) {
      //Reset outlogs
      setShowOutput(false);
      setShowText(false);

      setContent();
      setLoading(true);

      //Send request
      if (cellType === "code") {
        let request = executeCode({
          cell_id: path,
          msg_id: path.concat("_", Date.now()),
          code: code,
        });

        //Then show response - check if same msg_id
        request.then((response) => {
          if (response.status === 200) {
            //Use msg_id to get outlog
            let outlog = getOutlog(response.data.msg_id);
            outlog.then((response) => {
              if (response.status === 200) {
                setMsgStatus(response.data.status);
                setLoading(false);
                setShowOutput(true);
                //Handle image
                if (response.headers["content-type"] === "image/png") {
                  setContent(
                    <Box
                      component="img"
                      sx={{
                        maxWidth: "30vw",
                      }}
                      alt="variate.energy plot"
                      src={`data:image/png;base64,${response.data.content}`}
                    />
                  );
                }
                //Handle downloads
                else if (
                  response.headers["content-type"] === "application/json"
                ) {
                  let url = response.data.content;
                  setContent(
                    <Button variant="contained" onClick={() => onDownload(url)}>
                      Download
                    </Button>
                  );
                }
                //Handle string
                else {
                  setContent(response.data.content);
                }
              } else {
                //TODO: Try again before error msg.
                setShowOutput(true);
                setContent("Could not connect to variate.energy code.");
                setMsgStatus(response.data.status);
                setLoading(false);
              }
            });
          } else {
            //TODO: Try again before error msg.
            setShowOutput(true);
            setMsgStatus(response.data.status);
            setContent("Could not connect to variate.energy code.");
            setLoading(false);
          }
        });
      } else if (cellType === "text") {
        setLoading(false);
        setIsMounted(false);
        setShowText(true);
      } else {
        // Handle nl
        let request = executeNL({
          cell_id: path,
          msg_id: path.concat("_", Date.now()),
          code: code,
        });

        //Then show response - check if same msg_id
        request.then((response) => {
          if (response.status === 200) {
            //Use msg_id to get outlog
            let outlog = getOutlog(response.data.msg_id);
            outlog.then((response) => {
              if (response.status === 200) {
                console.log(response);

                setMsgStatus(response.data.status);
                setLoading(false);

                setContent(response.data.content);

                dispatch({ type: "ADD", data: focused, property: "code " + response.data.content });

              } else {
                //TODO: Try again before error msg.
                setShowOutput(true);
                setContent("Could not connect to variate.energy code.");
                setMsgStatus(response.data.status);
                setLoading(false);
              }
            });
          } else {
            //TODO: Try again before error msg.
            setShowOutput(true);
            setMsgStatus(response.data.status);
            setContent("Could not connect to variate.energy code.");
            setLoading(false);
          }
        });
      }
    }
  }

  function handleRemove() {
    removeFunction(path);
  }

  useEffect(() => {
    if (isMounted) {
      const currentEditor = editorRef.current;
      currentEditor.onDidFocusEditorText(() => {
        focusFunction(path);
      });
      setHeight(currentEditor.getModel().getLineCount() * 23);
    }
  }, [isMounted, path, focusFunction]);

  //Track cell content on blur
  useEffect(() => {
    if (isMounted) {
      editorRef.current.onDidBlurEditorWidget(() => {
        dispatch({ type: "UPDATE", key: path, data: cellType + code });
      });
    }
  }, [code, cellType, isMounted, dispatch, path]);

  return (
    <Box>
      <Stack spacing={1}>
        <Item>
          {isLoading ? (
            <IconButton disabled={true}>
              <CircularProgress color="inherit" thickness={3} size={23} />
            </IconButton>
          ) : (
            <IconButton onClick={handleRun} variant="outlined">
              <PlayCircleOutlineIcon />
            </IconButton>
          )}
          <Typography variant="caption">{cellType}</Typography>
          <IconButton
            onClick={() => handleRemove()}
            variant="outlined"
            style={{ float: "right" }}
          >
            <ClearSharpIcon fontSize="small" />
          </IconButton>
          {showText ? (
            <MarkdownCell content={code} enableText={enableText} />
          ) : (
            <Editor
              height={height}
              {...(cellType === "code"
                ? { defaultLanguage: "python" }
                : { defaultLanguage: "markdown" })}
              defaultValue={cellValue}
              onChange={handleEditorDidChange}
              onMount={handleEditorDidMount}
              path={path.toString()}
            />
          )}
        </Item>
        {showOutput ? <Outlog status={msgStatus} content={content} /> : <></>}
      </Stack>
    </Box>
  );
}
