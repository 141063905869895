import React, { useState, useContext } from "react";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import axios from "axios";
import axiosRetry from "axios-retry";
import EditorContainer from "./EditorContainer";
import Sidebar from "./Sidebar";
import Appbar from "./Appbar";
import { AppContext } from "../App";

const style = {
  position: "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid darkgray",
  boxShadow: 24,
  p: 4,
};

function Layout({ user, token }) {
  /* eslint-disable no-unused-vars */
  const { state, dispatch } = useContext(AppContext);
  /* eslint-disable no-unused-vars */

  //Handles backend communication for children
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URI;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.withCredentials = true;
  // axios.defaults.timeout = 1000 * 60 * 20;
  axiosRetry(axios, { retries: 3 });

  //Handles states incl. toggling of modals
  const [open, setOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Handle file saving
  const [saved, setSaved] = useState(true);
  const handleSaved = () => setSaved(true);
  const handleNotSaved = () => setSaved(false);

  //Set File
  const [file, setFile] = useState("");
  function updateFile(value) {
    setFile(value);
  }

  //Saving file /storageSave (handles saved state & provides feedback in modal)
  const saveFile = async () => {
    if (saved === false) {
      let response = axios.post("/storageSave?get_container=True", {
        params: {
          file_name: file,
          overwrite: "yes",
        },
        file_content: JSON.stringify(state),
      });
      return response
        .then((res) => {
          if (res.status === 200) {
            setModalMsg("Saved.");
            handleSaved();
            handleOpen();
          } else {
            handleNotSaved();
            setModalMsg("Could not save file.");
            handleOpen();
          }
          return res;
        })
        .catch((err) => {
          setModalMsg("Could not save file.");
          handleNotSaved();
          handleOpen();
          // console.log(err);
          return Promise.reject(err);
        });
    }
  };

  //Run code /compile
  const executeCode = async (value) => {
    try {
      const response = await axios.post("/compile", value);
      return response;
    } catch (error) {
      return { status: 500, data: { status: 3 } };
    }
  };

  //Run code /naturalng ######################################################################
  const executeNL = async (value) => {
    try {
      const response = await axios.post("/naturalng", value);
      return response;
    } catch (error) {
      return { status: 500, data: { status: 3 } };
    }
  };

  //Refresh session /refreshSession
  const refreshSession = async () => {
    try {
      await axios.post("/refreshSession").then((res) => {
        setModalMsg("Session has been reset.");
        handleOpen();
      });
    } catch (error) {
      setModalMsg("Could not reset session.");
      handleOpen();
    }
  };

  //Get response /getOutlog
  const getOutlog = async (value) => {
    try {
      const response = await axios.get(`/getOutput?msg_id=${value}`);
      return response;
    } catch (error) {
      return { status: 500, data: { status: 3 } };
    }
  };

  return (
    <>
      <Appbar user={user} />
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Grid container>
            <Grid item p={(10, 5)} xs={12}>
              {modalMsg}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid
        container
        height="100vh"
        alignItems="stretch"
        overflow="hidden"
        spacing={0}
        user={user}
      >
        <Grid item xs={1} user={user}>
          <Toolbar />
          <Sidebar
            user={user}
            updateFile={updateFile}
            saveFile={saveFile}
            saved={saved}
            file={file}
          />
        </Grid>
        <Grid
          item
          xs={11}
          style={{
            maxHeight: "100vh",
            overflow: "auto",
            backgroundColor: "lightgray",
          }}
        >
          <Toolbar />
          <EditorContainer
            saveFile={saveFile}
            executeCode={executeCode}
            executeNL={executeNL}
            refreshSession={refreshSession}
            getOutlog={getOutlog}
            handleNotSaved={handleNotSaved}
            saved={saved}
            file={file}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Layout;
