import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import AddIcon from "@mui/icons-material/Add";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Cell from "./Cell";
import { AppContext } from "../App";

export default function EditorContainer({
  saveFile,
  executeCode,
  executeNL,
  getOutlog,
  refreshSession,
  handleNotSaved,
  saved,
  file,
}) {
  //File Management
  const [enableSave, setEnableSave] = useState(false);
  const [enableAddCode, setEnableAddCode] = useState(false);
  const [enableAddText, setEnableAddText] = useState(false);
  const [enableAddNL, setEnableAddNL] = useState(false);
  const [enableRestart, setEnableRestart] = useState(false);

  useEffect(() => {
    if (saved) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
    if (file === "") {
      setEnableSave(true);
      setEnableAddCode(true);
      setEnableAddText(true);
      setEnableAddNL(true);
      setEnableRestart(true);
    } else {
      setEnableAddCode(false);
      setEnableAddText(false);
      setEnableAddNL(false);
      setEnableRestart(false);
    }
  }, [saved, file]);

  //Tracks which cell is focused to add cells underneath
  const [focused, setFocused] = useState(null);
  const { state, dispatch } = useContext(AppContext);

  function handleFocus(value) {
    setFocused(value);
  }

  function handleRemove(value) {
    setEnableSave(false);
    handleNotSaved();

    dispatch({ type: "REMOVE", data: value });
  }

  function handleAdd(value) {
    setEnableSave(false);
    handleNotSaved();

    switch (value) {
      case "code":
        dispatch({ type: "ADD", data: focused, property: "code" });
        break;
      case "text":
        dispatch({ type: "ADD", data: focused, property: "text" });
        break;
      case "lng ":
        dispatch({ type: "ADD", data: focused, property: "lng " });
        break;
      default:
        dispatch({ type: "ADD", data: focused, property: "code" });
        break;
    }
  }

  return (
    <Grid
      style={{
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "lightgray",
          borderBottom: "thin lightgray solid",
          textAlign: "left",
          width: "91.6%",
          position: "absolute",
          left: "8.4%",
          zIndex: 5,
          paddingTop: "12px",
          paddingBottom: "6px",
          paddingLeft: "6px",
          wrap: "nowrap",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => handleAdd("code")}
          variant="contained"
          startIcon={<AddIcon />}
          disabled={enableAddCode}
          sx={{
            marginRight: "12px",
            backgroundColor: "white",
            color: "rgba(0, 0, 0, 0.6)",
            ":hover": {
              color: "white",
            },
          }}
        >
          Add Code
        </Button>
        <Button
          onClick={() => handleAdd("text")}
          variant="contained"
          startIcon={<AddIcon />}
          disabled={enableAddText}
          sx={{
            marginRight: "12px",
            backgroundColor: "white",
            color: "rgba(0, 0, 0, 0.6)",
            ":hover": {
              color: "white",
            },
          }}
        >
          Add Text
        </Button>
        <Button
          onClick={() => handleAdd("lng ")}
          variant="contained"
          startIcon={<AddIcon />}
          disabled={enableAddNL}
          sx={{
            marginRight: "12px",
            backgroundColor: "white",
            color: "rgba(0, 0, 0, 0.6)",
            ":hover": {
              color: "white",
            },
          }}
        >
          Add Natural Language
        </Button>
        <Button
          variant="contained"
          startIcon={<RefreshSharpIcon />}
          onClick={refreshSession}
          disabled={enableRestart}
          sx={{
            backgroundColor: "white",
            marginRight: "12px",
            color: "rgba(0, 0, 0, 0.6)",
            ":hover": {
              color: "white",
            },
          }}
        >
          Restart
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveSharpIcon />}
          onClick={saveFile}
          disabled={enableSave}
          sx={{
            backgroundColor: "white",
            marginRight: "12px",
            color: "rgba(0, 0, 0, 0.6)",
            ":hover": {
              color: "white",
            },
          }}
        >
          Save
        </Button>
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "12px",
            width: "auto",
          }}
        >
          {saved ? <>{file}</> : <i>{file}</i>}
        </Box>
      </Grid>
      <Grid
        style={{
          padding: "6px",
        }}
      >
        <Toolbar />
        <Stack spacing={3}>
          {Object.keys(state).map((cell, index) => {
            return (
              <Cell
                key={cell}
                path={cell}
                cellType={state[cell].slice(0, 4)}
                cellContent={state[cell].slice(4)}
                removeFunction={handleRemove}
                focusFunction={handleFocus}
                executeCode={executeCode}
                executeNL={executeNL}
                getOutlog={getOutlog}
                saved={saved}
                focused={focused} //Track which cell is focused
                handleNotSaved={handleNotSaved}
              />
            );
          })}
        </Stack>
      </Grid>
    </Grid>
  );
}
