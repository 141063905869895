import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

function Help() {
  const [doc, setDoc] = useState([]);
  const [loading, isLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/getDocumentation", {
        "axios-retry": {
          retries: 3,
        },
      })
      .then((response) => {
        setDoc(JSON.parse(response.data.content));
        isLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setDoc({ Error: "Could not connect." });
        isLoading(false);
      });
  }, []);

  return (
    <Stack spacing={3}>
      {loading ? (
        <Box
          sx={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              opacity: "0.7",
            }}
          />
        </Box>
      ) : (
        Object.entries(doc).map(([k, v], index) => {
          return (
            <React.Fragment key={k}>
              <Box>
                <h3>{k}</h3>
                <div style={{ whiteSpace: "pre-wrap" }}>{v}</div>
              </Box>
              <Divider />
            </React.Fragment>
          );
        })
      )}
    </Stack>
  );
}

export default Help;
