import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

const style = {
  position: "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid darkgray",
  boxShadow: 24,
  color: "red",
  p: 4,
};

const Communication = (props) => {
  const { handleSubmit, control } = useForm();
  const { handleSubmit: handleSubmit1, control: control1 } = useForm();

  const [submitted, setSubmitted] = useState(false);
  const [reported, setReported] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");

  const communicationAPI = axios.create({
    baseURL: "https://prod-24.germanywestcentral.logic.azure.com:443/workflows",
    withCredentials: false,
  });
  communicationAPI.defaults.headers.post["Content-Type"] =
    "application/json; charset=UTF-8";
  communicationAPI.defaults.headers.common = {};

  useEffect(() => {
    if (props.user["given_name"]) {
      setFirstName(props.user["given_name"]);
    }
    if (props.user["name"]) {
      setLastName(props.user["name"]);
    }
    if (props.user["extension_company"]) {
      setCompany(props.user["extension_company"]);
    }
    if (props.user["jobTitle"]) {
      setJobTitle(props.user["jobTitle"]);
    }
    if (props.user["emails"][0]) {
      setEmail(props.user["emails"][0]);
    }
  }, [props.user]);

  const onReport = (value) => {
    value["topic"] = "bug_report";
    value["email"] = email;
    value["firstname"] = firstname;
    value["lastname"] = lastname;
    value["company"] = company;
    value["jobTitle"] = jobTitle;

    communicationAPI
      .post(
        "/ed2d94e399f44f61aab0ddd38c4adbb9/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=x1tyLKobTX8NfqF5BNm86rEa9stb8by2ijPfUOIrdkg",
        value,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(function (response) {
        setReported(true);
      })
      .catch(function (error) {
        setReported(false);
        handleOpen();
      });
  };

  const onSubmit = (value) => {
    value["topic"] = "feature_request";
    value["email"] = email;
    value["firstname"] = firstname;
    value["lastname"] = lastname;
    value["company"] = company;
    value["jobTitle"] = jobTitle;

    communicationAPI
      .post(
        "/ed2d94e399f44f61aab0ddd38c4adbb9/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=x1tyLKobTX8NfqF5BNm86rEa9stb8by2ijPfUOIrdkg",
        value,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(function (response) {
        setSubmitted(true);
      })
      .catch(function (error) {
        setSubmitted(false);
        handleOpen();
      });
  };

  return (
    <Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <i>
            Could not send. Please try again. <br />
            If the problem persists, please contact info@variate.energy.
          </i>
        </Box>
      </Modal>
      <Stack spacing={3}>
        <div>
          <h4>Feature Request</h4>
          Any features you would like to see implemented?
          <br />
          Feel free to suggest any features that would help you in your work and
          support us in improving variate.energy code!
        </div>
        <form key={1} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Controller
              name="message"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="standard-multiline-static"
                  multiline
                  rows={8}
                  fullWidth
                  variant="filled"
                  required
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {submitted ? (
              <i style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                Thank you - Your feature request has been submitted.
              </i>
            ) : (
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  marginRight: "12px",
                  color: "white",
                }}
              >
                Submit
              </Button>
            )}
          </Stack>
        </form>

        <Divider />
        <div>
          <h4>Bug Report</h4>
          Would like to report a bug? <br />
          Please include as much detail as possible to help us resolve the issue
          - if possible include code snippets.
        </div>
        <form key={2} onSubmit={handleSubmit1(onReport)}>
          <Stack spacing={3}>
            <Controller
              name="message"
              control={control1}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="standard-multiline-static-2"
                  multiline
                  rows={8}
                  fullWidth
                  variant="filled"
                  required
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {reported ? (
              <i style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                Thank you - The bug has been reported.
              </i>
            ) : (
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  marginRight: "12px",
                  color: "white",
                }}
              >
                Report
              </Button>
            )}
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};

export default Communication;
