import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import authentication from "react-azure-b2c";
// import axios from "axios";

authentication.initialize({
  // optional, will default to this
  instance: "https://login.microsoftonline.com/tfp/",
  // your B2C tenant
  tenant: "variateenergycode.onmicrosoft.com",
  // the policy to use to sign in, can also be a sign up or sign in policy
  signInPolicy: "B2C_1_signin",
  // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
  clientId: "9e3e5fc1-7b0e-41e6-9a0b-db69d8b37903",
  // where MSAL will store state - localStorage or sessionStorage
  cacheLocation: "localStorage",
  // the scopes you want included in the access token
  scopes: ["https://variateenergycode.onmicrosoft.com/tasks-api/tasks.read"],
  // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  postLogoutRedirectUri: window.location.origin,
});

authentication.run(() => {
  // axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URI;
  // var token = authentication.getAccessToken().accessToken;
  // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  // registerServiceWorker();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
