import React from "react";
import ReactMarkdown from "react-markdown";

export default function MarkdownCell({ content, enableText }) {
  function disableCell() {
    enableText(true);
  }

  return (
    <div style={{ width: "98%", margin: "auto" }} onClick={disableCell}>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
}
